<template>
	<div class="uu-live-page">
		<div class="page-title">{{$t('liveplatform.live.title')}}</div>
		<el-form :model="liveForm" ref="liveForm" label-position="top" size="small">
			<el-form-item>
				<div class="item-inline">
					<el-select v-model="liveForm.type" popper-class="live-select-popper">
						<el-option v-for="(item,index) in roomOptions" :key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div>
				<div class="item-inline">
					<el-select v-model="liveForm.screen" popper-class="live-select-popper">
						<el-option v-for="(item,index) in screenOptions" :key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div>
			</el-form-item>
			<el-form-item :label="$t('liveplatform.live.formItem1')" prop="cover0" :rules="{ required: true, validator: validateCover, trigger: ['blur', 'change'] }">
				<el-input v-show="false" v-model="liveForm.cover0"></el-input>
				<div class="page-cover">
					<div class="cover-space">
						<div class="cover-space-item mr12">
							<el-upload
								action="#"
								style="width: 90px;"
								:show-file-list="false"
								:http-request="httpRequestCover">
								<div class="page-upload">
									<template v-if="liveForm.cover0">
										<img :src="liveForm.cover0" alt="" width="100%">
										<div class="page-upload-tip">{{$t('liveplatform.live.reupload')}}</div>
									</template>
									<template v-else>
										<i class="el-icon-camera-solid"></i>
										<p>{{$t('liveplatform.live.formText1-1')}}</p>
										<p><span>{{$t('liveplatform.live.formText1-2')}}</span></p>
									</template>
								</div>
							</el-upload>
						</div>
						<div class="cover-space-item cover-title">
							<el-input
								type="textarea"
								:rows="3"
								v-model="liveForm.title"
								:placeholder="$t('liveplatform.placeholder.liveTitle')"
								maxlength="10"
								show-word-limit>
							</el-input>
						</div>
					</div>
				</div>
			</el-form-item>
			<el-form-item :label="$t('liveplatform.live.formItem2')" prop="setTime" :rules="{ required: true, message: $t('liveplatform.validate.liveTime'), trigger: 'change' }">
				<el-date-picker
					v-model="liveForm.setTime"
					type="datetime"
					:placeholder="$t('liveplatform.placeholder.liveStartTime')"
					value-format="timestamp"
					:picker-options="pickerEndDate"
					:default-value="new Date()"
					:default-time="pickerTime"
					popper-class="live-date-picker">
				</el-date-picker>
			</el-form-item>
			<el-form-item prop="position" :rules="{ required: true, message: $t('liveplatform.validate.livePosition'), trigger: 'change' }">
				<div class="page-region">
					<ul>
						<baidu-map @ready="onMapReady"></baidu-map>
						<template v-if="bmapDisabled">
							<li>
								<el-autocomplete
									v-model="liveForm.position"
									:placeholder="$t('liveplatform.placeholder.livePosition')"
									:fetch-suggestions="querySearchMap"
									@select="handleSelectMap"
									@blur="handleBlurMap"
									popper-class="live-select-popper"
									style="width: 400px;">
									<i
										class="el-icon-search el-input__icon"
										slot="suffix">
									</i>
								</el-autocomplete>
							</li>
							<li><span>{{$t('liveplatform.live.formText3-1')}}：{{address}}</span></li>
							<li><a href="javascript:void(0);" @click="handleLocation">{{$t('liveplatform.live.formText3-2')}}<i class="el-icon-refresh"></i></a></li>
							<li><a href="javascript:void(0);" @click="bmapDisabled = false">{{$t('liveplatform.live.formText3-3')}}</a></li>
						</template>
						<template v-else>
							<li v-for="(item,index) in regionList" :key="index">
								<el-select v-model="checkRegion[index]" clearable :placeholder="$t('liveplatform.placeholder.liveRegion')" @change="handleRegionChange($event,index)" popper-class="live-select-popper">
									<el-option
										v-for="(region,index2) in item"
										:key="index2"
										:label="region.region_name"
										:value="JSON.stringify(region)">
									</el-option>
								</el-select>
							</li>
						</template>
						
					</ul>
				</div>
			</el-form-item>
			<el-form-item :label="$t('liveplatform.live.formItem4')" prop="tagId" :rules="{ required: true, message: $t('liveplatform.validate.liveChannel'), trigger: ['blur', 'change'] }">
				<el-button type="block" icon="el-icon-s-operation" @click="dialogVisibleColumn = true">
					<span>{{$t('liveplatform.live.formText4-1')}}</span>
					<template v-if="liveForm.tagId">
						<span style="margin-left: 20px;margin-right: 5px;">{{liveForm.tagName}}</span>
						<i class="el-icon-arrow-right"></i>
					</template>
				</el-button>
				<el-input v-show="false" v-model="liveForm.tagId"></el-input>
			</el-form-item>
			<el-collapse v-model="collapseActive">
				<el-collapse-item :title="$t('liveplatform.live.notRequired')" name="1">
					<el-form-item :label="$t('liveplatform.live.formItem5')">
						<div class="w400">
							<el-input
								type="textarea"
								v-model="liveForm.intro"
								:rows="4"
								:placeholder="$t('liveplatform.placeholder.liveIntro')"
								maxlength="140"
								show-word-limit>
							</el-input>
						</div>
					</el-form-item>
					<el-form-item :label="$t('liveplatform.live.formItem6')">
						<el-upload
							action="#"
							:show-file-list="false"
							v-loading="loadingUplaod"
							accept="video/*"
							:http-request="httpRequestVideo"
							style="width: 90px;">
							<div class="page-upload">
								<template v-if="liveForm.pre_video_url">
									<video :src="liveForm.pre_video_url"></video>
									<div class="page-upload-tip">{{$t('liveplatform.live.reupload')}}</div>
								</template>
								<template v-else>
									<i class="el-icon-video-camera-solid"></i>
									<p>{{$t('liveplatform.live.formText6-1')}}</p>
									<p><span>{{$t('liveplatform.live.formText6-2')}}</span></p>
								</template>
							</div>
						</el-upload>
					</el-form-item>
					<el-form-item :label="$t('liveplatform.live.formItem7')">
						<div class="page-goods">
							<ul>
								<li v-for="(item,index) in pageGoods" :key="index">
									<div class="goods-item">
										<img class="pic" :src="item.defaultImage" alt="">
										<div class="price">￥{{item.price}}</div>
										<i class="el-icon-close item-colse" @click="handleRemoveGoods(index)"></i>
									</div>
								</li>
								<li>
									<div class="page-upload" @click="handleOpenGoods">
										<i class="el-icon-plus"></i>
										<p>{{$t('liveplatform.live.formText7-1')}}</p>
									</div>
								</li>
							</ul>
						</div>
						<!-- <div class="page-list">
							<ul>
								<li><el-checkbox>{{$t('liveplatform.live.tips1')}}</el-checkbox></li>
								<li><el-checkbox :disabled="true">{{$t('liveplatform.live.tips2')}}</el-checkbox><a href="javascript:void(0);">{{$t('liveplatform.live.explain')}}</a></li>
								<li><el-checkbox>{{$t('liveplatform.live.tips3')}}</el-checkbox></li>
								<li><el-checkbox>{{$t('liveplatform.live.tips4')}}</el-checkbox><a href="javascript:void(0);">{{$t('liveplatform.live.explain')}}</a></li>
							</ul>
						</div> -->
					</el-form-item>
				</el-collapse-item>
			</el-collapse>
			<el-form-item>
				<div class="page-foot">
					<el-button @click="handleSubmit" size="default" type="red" round>{{$t('liveplatform.live.submitBtn')}}</el-button>
				</div>
			</el-form-item>
		</el-form>
		<el-dialog
			:title="$t('liveplatform.live.dialogTitle1')"
			width="800px"
			:visible.sync="dialogVisibleCropper">
			<div class="dialog-cropper">
				<vueCropper
					ref="cropper"
					:img="option.img"
					:outputSize="option.size"
					:outputType="option.outputType"
					:info="true"
					:full="option.full"
					:canMove="option.canMove"
					:canMoveBox="option.canMoveBox"
					:original="option.original"
					:autoCrop="option.autoCrop"
					:autoCropWidth="option.autoCropWidth"
					:autoCropHeight="option.autoCropHeight"
					:fixed="option.fixed"
					:fixedNumber="option.fixedNumber"
					:centerBox="option.centerBox"
					:infoTrue="option.infoTrue"
					:fixedBox="option.fixedBox">
				</vueCropper>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="handleCancelCropper" size="small" round>{{$t('liveplatform.live.dialogCancel')}}</el-button>
				<el-button type="danger" @click="handleConfirmCropper" size="small" round v-loading="loadingUplaod">{{$t('liveplatform.live.dialogConfirm1')}}</el-button>
			</div>
		</el-dialog>
		<el-dialog
			:title="$t('liveplatform.live.dialogTitle2')"
			width="420px"
			class="live-dialog dialog-column"
			:visible.sync="dialogVisibleColumn">
			<div class="column-body">
				<div class="column-menu">
					<el-scrollbar>
						<el-menu :default-active="columnActive" @select="handleSelectColumn">
							<el-menu-item v-for="(item,index) in columnMenu" :index="item.tag_id.toString()" :key="index">
								{{item.tag_name}}
							</el-menu-item>
						</el-menu>
					</el-scrollbar>
				</div>
				<div class="column-mian">
					<el-scrollbar>
						<el-radio-group v-model="checkColumn" v-if="columnList && columnList.length>0">
							<el-radio v-for="(item,index) in columnList" :key="index" :label="item">{{item.tag_name}}</el-radio>
						</el-radio-group>
						<el-empty :description="$t('liveplatform.empty.data')" v-else></el-empty>
					</el-scrollbar>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="danger" @click="handleConfirmColumn" size="small" round>{{$t('liveplatform.live.dialogConfirm1')}}</el-button>
			</div>
		</el-dialog>
		<el-dialog
			:title="$t('liveplatform.live.dialogTitle3')"
			width="1040px"
			class="live-dialog dialog-goods"
			:visible.sync="dialogVisibleGoods">
			<div class="goods-top"><el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{$t('liveplatform.live.dialogCheckAll')}}</el-checkbox></div>
			<div class="goods-main">
				<el-scrollbar>
					<el-checkbox-group class="goods-list" v-model="checkGoods"  @change="handleCheckedGoodsChange">>
						<el-checkbox v-for="(item,index) in goodsList" :key="index" :label="JSON.stringify(item)">
							<div class="goods-item">
								<img class="pic" :src="item.defaultImage" alt="">
								<div class="item-main">
									<div class="name">{{item.goodsName}}</div>
									<div class="price">￥{{item.price}}</div>
								</div>
							</div>
						</el-checkbox>
					</el-checkbox-group>
				</el-scrollbar>
			</div>
			<div class="goods-bottom">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page.sync="pagecurr"
					:page-size="pagesize"
					layout="total, prev, pager, next"
					:prev-text="$t('liveplatform.pagination.prevText')"
					:next-text="$t('liveplatform.pagination.nextText')"
					:total="pagecount">
				</el-pagination>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="danger" @click="handleConfirmGoods" size="small" round>{{$t('liveplatform.live.dialogConfirm2')}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { fetchRegion } from '@/api/user';
	import { fetchChannelSection, fetchShopGoods, createLive } from '@/api/liveplatform';
	import { normalOSSUpload } from '@/utils/upload';
	export default {
		computed: {
			pickerTime(){
				let date = new Date();
				let h = date.getHours();
				h = h < 10 ? ('0' + h) : h;
				let i = date.getMinutes();
				i = i < 10 ? ('0' + i) : i;
				let s = date.getSeconds();
				s = s < 10 ? ('0' + s) : s;
				return h + ':' + i + ':' + s;
			}
		},
		data() {
			return {
				loadingUplaod: false,
				liveForm: {
					cover0: '',
					goods_ids: '',
					intro: '',
					position: '',
					lat: '',
					lon: '',
					pre_video_url: '',
					screen: 'vertical',
					setTime: '',
					startTime: '1',
					tagId: '',
					tagName: '',
					title: '',
					type: 'common',
				},
				roomOptions: this.$t('liveplatform.live.roomJson'),
				screenOptions: this.$t('liveplatform.live.screenJson'),
				bmapDisabled: true,
				BMap: '',
				map: '',
				address: '',
				point: {lng: 0, lat: 0 },
				mapParam: {
					point: {
						lat: 0,
						lng: 0,
					},
					value: ''
				},
				regionList: [],
				checkRegion: [],
				dialogVisibleCropper: false,
				option: {
					img: '', // 裁剪图片的地址
					info: true, // 裁剪框的大小信息
					outputSize: 0.8, // 裁剪生成图片的质量
					outputType: 'jpeg', // 裁剪生成图片的格式
					canScale: false, // 图片是否允许滚轮缩放
					autoCrop: true, // 是否默认生成截图框
					autoCropWidth: 375, // 默认生成截图框宽度
					autoCropHeight: 375, // 默认生成截图框高度
					fixedBox: true, // 固定截图框大小 不允许改变
					fixed: true, // 是否开启截图框宽高固定比例
					fixedNumber: [1, 1], // 截图框的宽高比例
					full: true, // 是否输出原图比例的截图
					canMoveBox: false, // 截图框能否拖动
					original: true, // 上传图片按照原始比例渲染
					centerBox: true, // 截图框是否被限制在图片里面
					infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
				},
				fileInfo: {},
				pickerEndDate: {
					disabledDate: (time) => {
						return time.getTime() < new Date().getTime()-8.64e7;
					},
					selectableRange: "00:00:00 - 23:59:59"
				},
				dialogVisibleColumn: false,
				columnMenu: [],
				columnActive: '',
				columnList: [],
				checkColumn: {},
				collapseActive: '',
				pageGoods: [],
				dialogVisibleGoods: false,
				pagecurr: 1,
				pagesize: 12,
				pagecount: 0,
				goodsList: [],
				checkAll: false,
				isIndeterminate: false,
				checkGoods: [],
			}
		},
		created() {
			this.getColumnMenu();
			this.getGoodsList();
			// this.getLocation();
			this.getRegionList();
		},
		methods: {
			validateCover(rule, value, callback){
				if(value === ''){
					callback(new Error(this.$t('liveplatform.validate.liveCover')));
				}else if(this.liveForm.title === ''){
					callback(new Error(this.$t('liveplatform.validate.liveTitle')));
				}else {
					callback();
				}
			},
			formatDate(val){
				if(val>0){
					let date = new Date(val);
					let y = date.getFullYear();
					let m = date.getMonth()+1;
					m = m < 10 ? ('0' + m) : m;
					let d = date.getDate();
					d = d < 10 ? ('0' + d) : d;
					return y+'-'+m+'-'+d;
				}
				return '';
			},
			httpRequestCover(data){
				let that = this;
				const istype = ['image/jpeg','image/png'].includes(data.file.type);
				const isSize = data.file.size / 1024 / 1024 < 2;
				if (!istype) {
					that.$message.error(this.$t('liveplatform.errorMsg.imgFileType'));
					return false;
				}
				if (!isSize) {
					that.$message.error(this.$t('liveplatform.errorMsg.imgFileSize2M'));
					return false;
				}
				let imgUrl = URL.createObjectURL(data.file);
				this.fileInfo = data.file;
				this.$nextTick(() => {
					that.option.img = imgUrl;
					that.dialogVisibleCropper = true;
				})
			},
			handleCancelCropper(){
				this.dialogVisibleCropper = false;
			},
			handleConfirmCropper(){
				this.loadingUplaod = true;
				this.$refs.cropper.getCropBlob(async(file) =>{
					file.name = this.fileInfo.name;
					const { code, data} = await normalOSSUpload(file,'live');
					if(code==200){
						this.liveForm.cover0 = data.url;
						this.dialogVisibleCropper = false;
					}else{
						this.$message.error(this.$t('liveplatform.errorMsg.upload'))
					}
					this.loadingUplaod = false;
				})
			},
			onMapReady({BMap,map}){
				this.BMap = BMap;
				this.map = map;
				this.$nextTick(()=>{
					this.handleLocation();
				})
				// console.log(BMap);
			},
			handleLocation(){
				let that = this;
				const BMap = this.BMap;
				if(!BMap) return;
				var geolocation = new BMap.Geolocation();
				geolocation.enableSDKLocation();
				geolocation.getCurrentPosition(function(e){
					if(this.getStatus() == window.BMAP_STATUS_SUCCESS){
						that.point = {lng:e.point.lng, lat:e.point.lat};
						let point = new BMap.Point(e.point.lng, e.point.lat);
						let gc = new BMap.Geocoder();
						gc.getLocation(point, function(res){
							let addComp = res.addressComponents;
							that.address = addComp.province+addComp.city;
						})
					}else {
						console.log(this.getStatus());
					}
				})
			},
			querySearchMap(queryString, cb){
				let that = this;
				var option = {
					onSearchComplete: function(results){
						var s = [{value: that.$t('liveplatform.marsText'),point:{lng:0, lat:0}}];
						if(local.getStatus() === 0){
							for(var i  =0;i<results.getCurrentNumPois();i++){
								var x = results.getPoi(i);
								var item  = {value:x.address+x.title,point:{lng:x.point.lng, lat:x.point.lat}};
								s.push(item);
							}
						}
						cb(s);
					}
				}
				var local = new this.BMap.LocalSearch(this.map,option)
				local.search(queryString)
			},
			handleSelectMap(item){
				this.mapParam = item;
			},
			handleBlurMap(){
				if(this.mapParam.value){
					this.liveForm.position = this.mapParam.value;
				}else{
					this.liveForm.position = '';
				}
			},
			getRegionList(pid=0,index=-1){
				fetchRegion({parent_id: pid}).then(response => {
					this.regionList.splice(index);
					if (response.data.list && response.data.list.length > 0) {
						this.regionList.push(response.data.list);
					}
				})
			},
			handleRegionChange(val, index){
				if(val){
					this.checkRegion.splice(index + 1);
					this.getRegionList(JSON.parse(val).region_id, index + 1);
				}else {
					this.checkRegion.splice(index);
					this.regionList.splice(index+1);
				}
			},
			getColumnMenu(){
				fetchChannelSection({app_key: this.$store.getters.appKey}).then(response => {
					this.columnMenu = response.data.list;
					this.handleSelectColumn(response.data.list[0].tag_id.toString());
				})
			},
			handleSelectColumn(key){
				this.columnActive = key;
				let list = [];
				this.columnMenu.forEach(item=>{
					if(item.tag_id == this.columnActive){
						list = item.child?item.child:[];
					}
				})
				this.columnList = list;
			},
			handleCancelColumn(){
				this.dialogVisibleColumn = false;
			},
			handleConfirmColumn(){
				this.liveForm.tagId = this.checkColumn.tag_id;
				this.liveForm.tagName = this.checkColumn.tag_name;
				this.dialogVisibleColumn = false;
			},
			async httpRequestVideo(value){
				let that = this;
				const { file } = value;
				const istype = file.type.indexOf('video');
				const isSize = file.size / 1024 / 1024 < 20;
				if (istype<0) {
					this.$message.error(this.$t('liveplatform.errorMsg.videoFileType'));
					return false;
				}
				if (!isSize) {
					this.$message.error(this.$t('liveplatform.errorMsg.videoFileSize20M'));
					return false;
				}
				this.loadingUplaod = true;
				const { code, data } = await normalOSSUpload(file,'video');
				if(code==200){
					this.liveForm.pre_video_url = data.url;
				}else{
					this.$message.error(this.$t('liveplatform.errorMsg.upload'))
				}
				this.loadingUplaod = false;
			},
			getGoodsList(){
				let param = {
					page_index: (this.pagecurr-1)*this.pagesize,
					page_size: this.pagesize,
					app_key: this.$store.getters.appKey
				}
				fetchShopGoods(param).then(response => {
					this.goodsList = response.data.list;
					this.pagecount = parseInt(response.data.total);
				})
			},
			handleSizeChange(val){
				this.pagesize = val;
			},
			handleCurrentChange(val){
				this.pagecurr = val;
				this.getGoodsList();
			},
			handleCheckAllChange(val){
				let list = [];
				if(val){
					this.goodsList.forEach(item=>{
						list.push(JSON.stringify(item));
					})
				}
				this.checkGoods = list;
				this.isIndeterminate = false;
			},
			handleCheckedGoodsChange(value){
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.goodsList.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.goodsList.length;
			},
			handleOpenGoods(){
				if(this.liveForm.tagId>0){
					let list = [];
					this.pageGoods.forEach(item=>{
						list.push(JSON.stringify(item));
					});
					this.checkGoods = list;
					let checkedCount = list.length;
					this.checkAll = checkedCount === this.goodsList.length;
					this.isIndeterminate = checkedCount > 0 && checkedCount < this.goodsList.length;
					this.dialogVisibleGoods = true;
				}else{
					this.$message.error(this.$t('liveplatform.errorMsg.liveColumn'));
				}
			},
			handleConfirmGoods(){
				let goods = [];
				this.checkGoods.forEach(item=>{
					goods.push(JSON.parse(item));
				})
				this.pageGoods = goods;
				this.dialogVisibleGoods = false;
			},
			handleRemoveGoods(index){
				this.pageGoods.splice(index,1);
			},
			handleSubmit(){
				let loading = this.$loading();
				if(this.bmapDisabled){
					if(this.mapParam.value){
						this.liveForm.lat = this.mapParam.point.lat;
						this.liveForm.lon = this.mapParam.point.lng;
						this.liveForm.position = this.mapParam.value;
					}
				} else {
					this.liveForm.lat = 0;
					this.liveForm.lon = 0;
				}
				let goodsIds = [];
				this.pageGoods.forEach(item=>{
					goodsIds.push(item.goodsId);
				})
				this.liveForm.goods_ids = goodsIds.join(',');
				this.$refs.liveForm.validate((valid)=>{
					if(valid){
						let option = JSON.parse(JSON.stringify(this.liveForm));
						let date = new Date();
						date.setMinutes(date.getMinutes()+10);
						if(option.setTime<Date.parse(date)){
							option.setTime = Date.parse(date)/1000;
						}else {
							option.setTime = option.setTime/1000;
						}
						let param = {
							...option,
							app_key: this.$store.getters.appKey,
							uuid: this.$store.getters.uuid
						}
						createLive(param).then(() => {
							loading.close();
							this.$router.push({name: 'LiveList'});
							this.$message.success(this.$t('liveplatform.successMsg.create'))
						}).catch(() => {
							loading.close();
						})
					}else {
						loading.close();
						return false;
					}
				})
			},
		},
		watch: {
			"liveForm.setTime": {
				handler(newVal,oldVal){
					if(newVal){
						let date = new Date();
						let st = "00:00:00";
						if(this.formatDate(date) == this.formatDate(newVal)){
							let h = date.getHours();
							h = h < 10 ? ('0' + h) : h;
							let i = date.getMinutes();
							i = i < 10 ? ('0' + i) : i;
							let s = date.getSeconds();
							s = s < 10 ? ('0' + s) : s;
							st = h+":"+i+":"+s;
						}
						this.pickerEndDate.selectableRange = st+" - 23:59:59";
					}
				},
				deep: true,
				immediate: true,
			},
			'checkRegion': {
				handler(newVal,oldVal){
					if(newVal){
						let address = '';
						newVal.forEach(item=>{
							address+=JSON.parse(item).region_name;
						})
						this.liveForm.position = address;
					}
				},
				deep: true
			}
		}
	}
</script>

<style lang="scss" scoped>
	.uu-live-page {
		padding: 20px;
		color: $--live-text-color-2;
		font-size: 14px;
		line-height: 1.5715;
		background-color: $--live-background-default;
		.page-title {
			color: #FFFFFF;
			font-size: 16px;
			margin-bottom: 20px;
		}
		.item-inline {
			display: inline-block;
			margin-right: 10px;
		}
		.w400 {
			width: 400px;
		}
		::v-deep .el-form {
			.el-form-item {
				.el-form-item__label {
					color: #FFFFFF;
				}
			}
		}
		::v-deep .el-input {
			.el-input__inner {
				color: $--live-text-color-2;
				border-color: transparent;
				background-color: $--live-input-background;
			}
			.el-input__inner:hover {
				border-color: $--live-input-hover-2;
			}
			.el-input__inner:focus {
				border-color: $--live-input-hover-1;
			}
			.el-input__prefix {
				color: $--live-text-color-2;
			}
			.el-input__suffix {
				color: $--live-text-color-2;
			}
		}
		::v-deep .el-textarea {
			.el-textarea__inner {
				color: $--live-text-color-2;
				border-radius: 6px;
				border-color: transparent;
				background-color: $--live-input-background;
				resize: none;
			}
			.el-textarea__inner:focus {
				border-color: $--live-input-hover-2;
			}
			.el-input__count {
				color: $--live-text-color-2;
				line-height: normal;
				background-color: $--live-input-background;
			}
		}
		::v-deep .el-button--block {
			font-size: 14px;
			color: rgba(255,255,255,.7);
			border-radius: 6px;
			border-color: $--live-button-gray-border;
			background-color: $--live-button-gray-background;
		}
		::v-deep .el-collapse {
			border-color: transparent;
			.el-collapse-item__header {
				color: #FFFFFF;
				display: inline-flex;
				background-color: transparent;
				border-color: transparent;
				.el-collapse-item__arrow {
					margin-left: 10px;
				}
			}
			.el-collapse-item__wrap {
				border-color: transparent;
				background-color: transparent;
			}
		}
		.page-upload {
			width: 90px;
			height: 90px;
			display: flex;
			color: $--live-text-color-2;
			position: relative;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			border-radius: 6px;
			overflow: hidden;
			cursor: pointer;
			background-color: $--live-gray-white-6;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			video {
				width: 100%;
				height: 100%;
			}
			p {
				color: #FFFFFF;
				font-size: 12px;
				line-height: 18px;
				margin-top: 2px;
				span {
					color: $--live-text-color-2;
				}
			}
			i {
				font-size: 30px;
			}
			.page-upload-tip {
				left: 0;
				bottom: 0;
				color: #FFFFFF;
				font-size: 12px;
				padding: 3px 0;
				line-height: 18px;
				width: 100%;
				text-align: center;
				position: absolute;
				background-color: rgba(0,0,0,.8);
			}
		}
		.page-cover {
			padding: 20px;
			border-radius: 6px;
			display: inline-block;
			background-color: $--live-background-color;
			.cover-space {
				display: inline-flex;
				.cover-space-item {
					display: flex;
				}
				.mr12 {
					margin-right: 12px;
				}
				.cover-title {
					width: 430px;
					height: 90px;
					::v-deep .el-textarea {
						.el-textarea__inner {
							height: 90px;
						}
						.el-textarea__inner:focus {
							border-color: rgba(166,29,36,.2);
						}
					}
				}
			}
		}
		.page-region {
			li {
				display: inline-block;
				margin-right: 15px;
			}
			a {
				color: $--live-text-color-2;
			}
		}
		.page-goods {
			li {
				display: inline-block;
				vertical-align: top;
				margin-right: 15px;
				margin-bottom: 15px;
			}
			.goods-item {
				width: 90px;
				height: 90px;
				position: relative;
				.pic {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 6px;
				}
				.price {
					right: 0;
					bottom: 0;
					height: 22px;
					max-width: 100%;
					box-sizing: border-box;
					overflow: hidden;
					text-overflow: ellipsis;
					line-height: 22px;
					padding: 0 6px;
					color: #FFFFFF;
					font-size: 14px;
					position: absolute;
					background-color: rgba(0,0,0,.5);
				}
				.item-colse {
					top: -9px;
					right: -9px;
					font-size: 12px;
					z-index: 2;
					color: #FFFFFF;
					position: absolute;
					padding: 3px;
					cursor: pointer;
					border-radius: 10px;
					background-color: #333333;
				}
			}
		}
		.page-list {
			li {
				line-height: 22px;
				margin-top: 12px;
			}
			::v-deep .el-checkbox {
				.el-checkbox__input {
					.el-checkbox__inner {
						border-radius: 7px;
					}
				}
				.el-checkbox__input.is-checked {
					.el-checkbox__inner {
						border-color: $--live-checkbox-primary;
						background-color: $--live-checkbox-primary;
					}
				}
				.el-checkbox__label {
					color: $--live-text-color-2;
				}
				.el-checkbox__input.is-disabled+span.el-checkbox__label {
					color: $--live-gray-white-20;
				}
				.el-checkbox__input.is-disabled {
					.el-checkbox__inner {
						border-color: $--live-gray-white-12;
						background-color: rgba(255,255,255,.08);
					}
				}
			}
			a {
				color: #5295ff;
				margin-left: 10px;
			}
		}
		.dialog-cropper {
			height: 500px;
		}
		.dialog-column {
			::v-deep .el-dialog__body {
				padding: 0;
			}
			::v-deep .el-dialog__footer {
				padding: 18px;
				background-color: $--live-gray-white-6;
			}
			.column-body {
				height: 490px;
				display: flex;
				border-top: 1px solid $--live-gray-white-4;
			}
			.column-menu {
				width: 95px;
				height: 100%;
				margin-right: 10px;
				background-color: $--live-gray-white-6;
				::v-deep .el-scrollbar {
					height: 100%;
				}
				::v-deep .el-menu {
					background-color: $--live-gray-white-6;
					border-right: none;
					.el-menu-item {
						color: rgba(255,255,255,.6);
						height: 46px;
						line-height: 46px;
					}
					.el-menu-item:hover,.el-menu-item:focus {
						background-color: transparent;
					}
					.el-menu-item.is-active {
						color: #FFFFFF;
						background-color: $--live-background-default;
					}
				}
			}
			.column-mian {
				flex: 1;
				width: 0;
				height: 100%;
				::v-deep .el-scrollbar {
					height: 100%;
				}
				::v-deep .el-radio-group {
					padding: 8px;
					display: flex;
					flex-wrap: wrap;
					.el-radio {
						width: 50%;
						flex-shrink: 0;
						padding: 6px 0;
						line-height: 20px;
						margin-bottom: 6px;
						margin-right: 0;
						.el-radio__input {
							.el-radio__inner {
								background-color: transparent;
							}
							.el-radio__inner:after {
								background-color: $--live-radio-primary;
							}
							.el-radio__inner:hover {
								border-color: $--live-radio-primary;
							}
						}
						.el-radio__label {
							color: #FFFFFF;
						}
						.el-radio__input.is-checked {
							.el-radio__inner {
								border-color: $--live-radio-primary;
							}
						}
					}
				}
			}
		}
		.dialog-goods {
			::v-deep .el-dialog__body {
				padding: 0;
			}
			.goods-main {
				padding: 0 20px;
				height: 500px;
			}
			.goods-top {
				padding: 10px 40px;
				margin-bottom: 10px;
				::v-deep .el-checkbox {
					color: $--live-gray-white-52;
					.el-checkbox__input {
						&.is-checked {
							.el-checkbox__inner {
								background-color: $--live-checkbox-primary;
								border-color: $--live-checkbox-primary;
							}
						}
						&.is-indeterminate {
							.el-checkbox__inner {
								background-color: $--live-checkbox-primary;
								border-color: $--live-checkbox-primary;
							}
						}
					}
				}
			}
			.goods-list {
				::v-deep .el-checkbox {
					position: relative;
					margin: 0 15px 15px;
					.el-checkbox__input {
						top: 10px;
						right: 10px;
						position: absolute;
						&.is-checked {
							.el-checkbox__inner {
								background-color: $--live-checkbox-primary;
								border-color: $--live-checkbox-primary;
							}
						}
					}
					.el-checkbox__label {
						padding-left: 0;
					}
				}
				.goods-item {
					width: 136px;
					.pic {
						width: 136px;
						height: 136px;
						border-radius: 6px;
						object-fit: cover;
					}
					.item-main {
						font-size: 12px;
						line-height: 18px;
						padding: 6px;
						.name {
							height: 36px;
							font-size: 12px;
							white-space: normal;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							color: rgba(255,255,255,.85);
						}
						.price {
							color: $--live-link-color-1;
						}
					}
				}
			}
			.goods-bottom {
				padding: 10px 0;
				text-align: center;
				::v-deep .el-pagination {
					button:disabled {
						background-color: transparent;
					}
					.el-pagination__total {
						color: $--live-text-color-2;
					}
					.btn-next,.btn-prev {
						color: $--live-text-color-2;
						background-color: transparent;
					}
					.el-pager {
						li {
							color: $--live-text-color-2;
							background-color: transparent;
							&.active,&:hover {
								color: $--live-link-hover-1;
							}
						}
						li.btn-quicknext {
							color: $--live-text-color-2;
						}
					}
				}
			}
		}
		.page-foot {
			text-align: center;
			::v-deep .el-button--red {
				padding: 14px 43px;
				border-radius: 24px;
				color: #FFFFFF;
				font-size: 18px;
				border-color: $--live-button-primary-border;
				background-color: $--live-button-primary-background;
				&:hover{
					border-color: $--live-button-primary-hover-border;
					background-color: $--live-button-primary-hover-background;
				}
			}
		}
	}
</style>